<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <div class="fill-height fill-width">
      <v-app
        class="app"
      >
        <div
          class="fill-height fill-width d-flex flex-column overflow-y-auto"
        >
          <core-view />
        </div>
      </v-app>

      <o-confirm-dialog ref="confirm" />
    </div>
  </transition>
</template>

<script>
  export default {
    components: {
      CoreView: () => import('@/views/auth/components/core/View'),
    },

    mounted () {
      this.$root.$confirm = this.$refs.confirm.open;
    },
  };
</script>

<style scoped lang="sass">
  .app
    position: relative
</style>
